import { NgModule,CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { HttpClientModule } from '@angular/common/http';
import { HTTP } from '@ionic-native/http/ngx';
import { IonicStorageModule } from '@ionic/storage';

import { Camera } from '@ionic-native/camera/ngx';
import { FingerprintAIO } from '@ionic-native/fingerprint-aio/ngx';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { LocationAccuracy } from '@ionic-native/location-accuracy/ngx';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';
import { NativeGeocoder} from '@ionic-native/native-geocoder/ngx';
import { File } from '@ionic-native/file/ngx';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { FileOpener } from '@ionic-native/file-opener/ngx';

import {SearchDepartmentPageModule} from './modal/search-department/search-department.module';
import { SearchBranchPageModule } from './modal/search-branch/search-branch.module';
import { SearchDesignationPageModule } from './modal/search-designation/search-designation.module';
import { SearchEmployeePageModule } from './modal/search-employee/search-employee.module';
import { SearchClientPageModule } from './modal/search-client/search-client.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FilterPageModule } from './modal/filter/filter.module';
import { LocalNotifications } from '@ionic-native/local-notifications/ngx';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { ActionPageModule } from './modal/action/action.module';

@NgModule({
  declarations: [AppComponent, ],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot({
      hardwareBackButton: true
    }),
    IonicStorageModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    SearchDepartmentPageModule,
    SearchBranchPageModule,
    SearchDesignationPageModule,
    SearchEmployeePageModule,
    SearchClientPageModule,
    BrowserAnimationsModule,
    FilterPageModule,
    ActionPageModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
  ],
  providers: [
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    HTTP,
    Camera,
    FingerprintAIO,
    Geolocation,
    LocationAccuracy,
    AndroidPermissions,
    NativeGeocoder,
    File,
    SocialSharing,
    FileOpener,
    LocalNotifications,
    InAppBrowser
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
