import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ApiService } from 'src/app/services/api.service';
import { AlertMessageService } from 'src/app/services/alert-message.service';

@Component({
  selector: 'app-search-designation',
  templateUrl: './search-designation.page.html',
  styleUrls: ['./search-designation.page.scss'],
})
export class SearchDesignationPage implements OnInit {
  text: any;
  Designation: any = [];
  Designation_search: any = [];
  info: any = [];
  info_data: any = [];
  empty_designation: number;
  @Input() page: any;
  checkbox = false;
  constructor(
    private modal: ModalController,
    private request: ApiService,
    private message: AlertMessageService
  ) {}

  ngOnInit() {
    this.getDesignation();
  }

  async close() {
    await this.modal.dismiss({}, 'dismiss');
  }

  async selectField(data) {
    if(!this.checkbox){
      await this.modal.dismiss(data, 'search');
    }
  }
  async selectMultipleDesignations(){
    // console.log('select client =>', this.Client);
    const data = await this.Designation.filter(item => item.checked == true);
    // console.log('select client true =>', data);
    await this.modal.dismiss(data, 'search');
  }
  count(){
    const data =  this.Designation.filter(item => item.checked == true);
    return data ? data.length : 0;
  }
  search() {
    this.Designation = [];
    if (!this.text) {
      this.Designation = this.Designation_search;
    } else {
      this.Designation_search.filter((item) => {
        if (item.name && this.text) {
          if (item.name.toLowerCase().indexOf(this.text.toLowerCase()) > -1) {
            this.Designation.push(item);
          }
        }
      });
    }
  }
  getDesignation() {
    const designation = this.page === 'employee' ? 0 : 1;
    const data = {designation_id: designation};
    this.request.newPostRequest('getdesignationsdropdown.php', data).subscribe((res: any) => {
          if (res.status === 'success') {
            if(this.checkbox){
              res.data.forEach((item) => {
                item.checked = false;
              })
            }
            this.Designation_search = this.Designation = res.data;
            this.empty_designation = (this.Designation.length === 0) ? 1 : this.Designation.length;
          } else {
            this.empty_designation = 1;
            if (res.data === 'Token Does Not Exist') {
              this.request.check_token();
             } else {
               this.message.toastMessage('No Record Found');
             }
          }
        },
        (err) => {
          this.empty_designation = 1;
          this.message.toastMessage(err.statusText);
        }
      );
  }
}
