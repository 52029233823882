import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ApiService } from 'src/app/services/api.service';
import { AlertMessageService } from 'src/app/services/alert-message.service';

@Component({
  selector: 'app-search-branch',
  templateUrl: './search-branch.page.html',
  styleUrls: ['./search-branch.page.scss'],
})
export class SearchBranchPage implements OnInit {
  text: any;
  Branch: any = [];
  Branch_search: any = [];
  info: any = [];
  info_data: any = [];
  empty_branch: number;
  type = false;
  constructor(
    private modal: ModalController,
    private request: ApiService,
    private message: AlertMessageService,
  ) {}
  ngOnInit() {
    this.getBranch();
  }
  async close() {
    await this.modal.dismiss({}, 'dismiss');
  }
  async selectField(data) {
    await this.modal.dismiss(data, 'search');
  }
  search() {
    this.Branch = [];
    if (!this.text) {
      this.Branch = this.Branch_search;
    } else {
      this.Branch_search.filter((item) => {
        if (item.name && this.text) {
          if (item.name.toLowerCase().indexOf(this.text.toLowerCase()) > -1) {
            this.Branch.push(item);
          }
        }
      });
    }
  }
  getBranch() {
    this.request.newPostRequest('getbranchesdropdown.php', {}).subscribe(
        (data: any) => {
          this.info = data;
          if (this.info.status === 'success') {
            this.Branch_search = this.Branch = this.info.data;
            if (this.type === true) {
              this.Branch.unshift({id: '0' , name: 'All'});
            }
            this.empty_branch = (this.Branch.length === 0) ? 1 : this.Branch.length;
          } else {
            this.empty_branch = 1;
            if (this.info.data === 'Token Does Not Exist') {
              this.request.check_token();
             } else {
               this.message.toastMessage('No Record Found');
             }
          }
        },
        (err) => {
          this.empty_branch = 1;
          this.message.toastMessage(err.statusText);
        }
      );
  }
}
