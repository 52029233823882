import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ApiService } from 'src/app/services/api.service';
import { AlertMessageService } from 'src/app/services/alert-message.service';
import { DepartmentsDropDown } from 'src/app/modals/searches.modal';
@Component({
  selector: 'app-search-department',
  templateUrl: './search-department.page.html',
  styleUrls: ['./search-department.page.scss'],
})
export class SearchDepartmentPage implements OnInit {
  text: any;
  Department: DepartmentsDropDown[] = [];
  Department_search: any = [];
  info: any = [];
  info_data: any = [];
  empty_department: number;
  user: any;
  groupId: any;
  branch_id: any;
  type = false;
  constructor(
    private modal: ModalController,
    private request: ApiService,
    private message: AlertMessageService
  ) {}
  ngOnInit() {
    this.getDepartment(this.branch_id);
  }
  async close() {
    await this.modal.dismiss({}, 'dismiss');
  }
  async selectField(data) {
    await this.modal.dismiss(data, 'search');
  }
  search() {
    this.Department = [];
    if (!this.text) {
      this.Department = this.Department_search;
    } else {
      this.Department_search.filter((item) => {
        if (item.name && this.text) {
          if (item.name.toLowerCase().indexOf(this.text.toLowerCase()) > -1) {
            this.Department.push(item);
          }
        }
      });
    }
  }
  getDepartment(id) {
    this.request
      .newPostRequest('getdepartmentsdropdown.php', { branch_id: id })
      .subscribe(
        (data: any) => {
          this.info_data = this.info = data;
          if (this.info_data.status === 'success') {
            this.empty_department = 1;
            if (this.groupId === '2') {
              if (this.info_data.data.length) {
                this.info_data.data.forEach((item) => {
                  if (item.id === this.user.department) {
                    this.Department.push(item  as DepartmentsDropDown);
                  }
                });
              }
            } else {
              this.Department = this.info_data.data as DepartmentsDropDown[];
            }
            if (this.type === true){
              this.Department.unshift({id: '0' , name: 'All'});
            }
            this.Department_search = this.Department;
            this.empty_department =
              this.Department.length === 0 ? 1 : this.Department.length;
          } else {
            this.empty_department = 1;
            if (this.info_data.data === 'Token Does Not Exist') {
              this.request.check_token();
             } else {
               this.message.toastMessage('No Record Found');
             }
          }
        },
        (err) => {
          this.empty_department = 1;
          this.message.toastMessage(err.statusText);
        }
      );
  }
}
