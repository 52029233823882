import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { Platform, AlertController, NavController } from "@ionic/angular";
import { Storage } from "@ionic/storage";
import { Router } from "@angular/router";

const Token = "auth-token";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  authenticationState = new BehaviorSubject(false);

  constructor(
    private storage: Storage,
    private plt: Platform,
    private router: Router,
    private alertController: AlertController,
    private navCtrl: NavController
  ) {
    this.plt.ready().then(() => {
      this.checkToken();
    });
  }
  async checkToken() {
    if (localStorage.getItem("token")) {
      this.authenticationState.next(true);
      this.navCtrl.navigateRoot("dashboard");
    } else {
      this.navCtrl.navigateRoot("login");
    }
  }
  login() {
    this.storage.set(Token, "ok 123").then(() => {
      this.authenticationState.next(true);
    });
  }
  async logout() {
    const alert = await this.alertController.create({
      header: "Manage HR",
      message: "Are you sure you want to log out?",
      buttons: [
        {
          text: "Cancel",
          role: "cancel",
          cssClass: "alertbtn",
          handler: (blah) => {},
        },
        {
          text: "Exit",
          cssClass: "alertbtn",
          handler: () => {
            this.clearMemory();
            this.navCtrl.navigateRoot("login");
            this.authenticationState.next(false);
          },
        },
      ],
    });

    await alert.present();
  }
  isAuthenticated() {
    return this.authenticationState.value;
  }
  async exit() {
    const alert = await this.alertController.create({
      header: "Manage HR",
      message: "Are you sure you want to exit?",
      buttons: [
        {
          text: "Cancel",
          role: "cancel",
          cssClass: "alertbtn",
          handler: (blah) => {},
        },
        {
          text: "Exit",
          cssClass: "alertbtn",
          handler: () => {
            navigator["app"].exitApp();
          },
        },
      ],
    });

    await alert.present();
  }
  clearMemory() {
    localStorage.clear();
    this.storage.clear();

  }
}
