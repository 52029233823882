import { Component, OnInit } from '@angular/core';
import { NavParams, PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-action',
  templateUrl: './action.page.html',
  styleUrls: ['./action.page.scss'],
})
export class ActionPage implements OnInit {

  view = false;
  edit = false;
  delete = false;
  status = false;
  constructor(public popoverController: PopoverController,
    public navParams:NavParams) {
      const data =  this.navParams.get('data');
      console.log('data : ', data);
      this.view = data.view;
      this.edit = data.edit;
      this.delete = data.delete;
      this.status = data.status;
   }

  ngOnInit() {
  }
  async itemSelect(title){
    await this.popoverController.dismiss(title, 'search');
   }

}
