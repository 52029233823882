import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ApiService } from 'src/app/services/api.service';
import { AlertMessageService } from 'src/app/services/alert-message.service';

@Component({
  selector: 'app-search-client',
  templateUrl: './search-client.page.html',
  styleUrls: ['./search-client.page.scss'],
})
export class SearchClientPage implements OnInit {
  text: any;
  Client: any = [];
  Client_search: any = [];
  info: any = [];
  info_data: any = [];
  empty_client: number;
  branch_id: any;
  user: any;
  groupId: any;
  checkbox = false;
  Count = 1;
  constructor(
    private modal: ModalController,
    private request: ApiService,
    private message: AlertMessageService
  ) {}
  ngOnInit() {
    console.log('checkbox =>', this.checkbox);
    this.user = JSON.parse(localStorage.getItem('user'));
    this.groupId = this.user.group_id;
    this.branch_id = this.groupId === '7' ? 0 : this.user.branch;
    this.getClient(this.branch_id);
  }
  async close() {
    await this.modal.dismiss({}, 'dismiss');
  }
  async selectField(data) {
    if(!this.checkbox){
      await this.modal.dismiss(data, 'search');
    }
  }
  async selectMultipleClient(){
    // console.log('select client =>', this.Client);
    const data = await this.Client.filter(item => item.checked == true);
    console.log('select client true =>', data);
    await this.modal.dismiss(data, 'search');
  }
   count(){
    const data =  this.Client.filter(item => item.checked == true);
    return data ? data.length : 0;
  }
  search() {
    this.Client = [];
    if (!this.text) {
      this.Client = this.Client_search;
    } else {
      this.Client_search.filter((item) => {
        if (item.client_name && this.text) {
          if (
            item.client_name.toLowerCase().indexOf(this.text.toLowerCase()) > -1
          ) {
            this.Client.push(item);
          }
        }
      });
    }
  }
  getClient(id) {
    this.request.newPostRequest('getclientsdropdown.php', {branch_id: id}).subscribe((res: any) => {
          // this.info_data = data;
          if (res.status === 'success') {
            if(this.checkbox){
              res.data.forEach((item) => {
                item.checked = false;
              })
            }
            
            this.Client_search = this.Client = res.data;
            this.empty_client = (this.Client.length === 0) ? 1 : this.Client.length;
            console.log('client =>', this.Client);
          } else {
            this.empty_client = 1;
            if (this.info_data.data === 'Token Does Not Exist') {
              this.request.check_token();
             } else {
               this.message.toastMessage('No Record Found');
             }
          }
        },
        (err) => {
          this.empty_client = 1;
          this.message.toastMessage(err.statusText);
        }
      );
  }
}
