import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup} from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { SearchBranchPage } from 'src/app/modal/search-branch/search-branch.page';
import { AlertMessageService } from 'src/app/services/alert-message.service';
import { CommonService } from 'src/app/services/common.service';
import { SearchDepartmentPage } from '../search-department/search-department.page';
import { SearchEmployeePage } from '../search-employee/search-employee.page';
import * as moment from 'moment';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.page.html',
  styleUrls: ['./filter.page.scss'],
  providers: [DatePipe]
})
export class FilterPage implements OnInit {

  filter: FormGroup;
  groupId: any;
  branch: any;
  branchName: any;
  department: any;
  departmentName: any;
  employee: any;
  employeeName: any;
  employeeId: any;
  departmentId: any;
  branchId: any;
  page = '';
  Leave_type = {
      wedding: 'Wedding',
      exams: 'Exams',
      other: 'Other',
    };
  Status = {
    approved: 'APPROVED',
    pending: 'PENDING',
    rejected: 'REJECTED',
  };
  taskStatus = {
    completed: 'Completed',
    in_progress: 'In Progress',
    open: 'Open',
  };
  StatusValue = [];
  constructor(
    private modalCtrl: ModalController,
    private fb: FormBuilder,
    private datePipe: DatePipe,
    private msg: AlertMessageService,
    private common: CommonService
    ) {
    this.setForm();
   }
  ngOnInit() {
    this.StatusValue = this.common.getStatus;
  }
  setForm() {
    this.filter = this.fb.group({
      time: [''],
      from_date: [''],
      to_date: [''],
      leave_type: [''],
      status: [''],
      due_days: [''],
      date: [''],
      period: [''],
      year: [''],
      month: ['']
    });
    const user = JSON.parse(localStorage.getItem('user'));
    this.groupId = user.group_id;
    this.branch = (this.groupId === '7') ? 0 : user.branch;
    this.department =  (this.groupId === '7' || this.groupId === '1') ? 0 : user.department;
    this.employee = (this.groupId === '7' || this.groupId === '1' || this.groupId === '2') ? 0 : user.employee_id;
    this.employeeName = user.username;
    this.departmentName = user.department_name;
    this.branchName = user.branch_name;
    this.employeeId = user.employee_id;
    this.departmentId = user.department;
    this.branchId = user.branch;
  }
  dismiss() {
    this.modalCtrl.dismiss({}, 'dismiss');
  }
  async getBranch() {
    this.departmentName = '';
    this.employeeName = '';
    const modal = await this.modalCtrl.create({
      component: SearchBranchPage,
      cssClass: 'customer',
      componentProps: {
        type: true
      }
    });
    await modal.present();
    const response = await modal.onDidDismiss();
    if (response.role === 'search') {
      this.branch = response.data.id;
      this.branchName = response.data.name;
    }
  }
  async Department() {
    if (this.groupId === '7') {
        if (!this.branchName) {
          this.msg.toastMessage('Please select Branch');
          return;
        }
    }
    this.employeeName = '';
    const modal = await this.modalCtrl.create({
      component: SearchDepartmentPage,
      cssClass: 'customer',
      componentProps: {
        branch_id:  this.branch,
        type: true
      }
    });
    await modal.present();
    const response = await modal.onDidDismiss();
    if (response.role === 'search') {
      this.department = response.data.id;
      this.departmentName = response.data.name;
    }
  }
  async Employee() {
    if (this.groupId === '7') {
      if (!this.branchName) {
        this.msg.toastMessage('Please select Branch');
        return;
      }
      if (!this.departmentName) {
        this.msg.toastMessage('Please select Department');
        return;
      }
    }
    if (this.groupId === '1') {
      if (!this.departmentName) {
        this.msg.toastMessage('Please select Department');
        return;
      }
    }
    const modal = await this.modalCtrl.create({
      component: SearchEmployeePage,
      cssClass: 'customer',
      componentProps: {
        department_id:  this.department,
        designation_id: '0',
        type: this.page == 'summary' ? false : true
      }
    });
    await modal.present();
    const response = await modal.onDidDismiss();
    if (response.role === 'search') {
      this.employee = response.data.emp_id;
      this.employeeName = response.data.employee_name;
    }
  }
  submit() {
    if (this.groupId === '7') {
      if (!this.branchName) {
        this.msg.toastMessage('Please select Branch');
        return;
      }
      if (!this.departmentName) {
        this.msg.toastMessage('Please select Department');
        return;
      }
      if (!this.employeeName) {
        this.msg.toastMessage('Please select Employee');
        return;
      }
    }
    if (this.groupId === '1') {
      if (!this.departmentName) {
        this.msg.toastMessage('Please select Department');
        return;
      }
      if (!this.employeeName) {
        this.msg.toastMessage('Please select Employee');
        return;
      }
    }
    if (this.groupId === '2') {
      if (!this.employeeName) {
        this.msg.toastMessage('Please select Employee');
        return;
      }
    }
    const formData = this.filter.value;
    if (!formData.from_date && this.page !== 'service'
          && this.page !== 'duetask' && this.page !== 'absentEmployee' 
          && this.page !== 'summary' && this.page !== 'expense' ) {
      this.msg.toastMessage('Please select From Date');
      return;
    }
    if (!formData.to_date && this.page !== 'service' 
     && this.page !== 'duetask' && this.page !== 'absentEmployee' 
     && this.page !== 'summary' && this.page !== 'expense') {
      this.msg.toastMessage('Please select To Date');
      return;
    }
    if ((!formData.period  && this.page == 'summary') || (!formData.period && this.page == 'expense')) {
      this.msg.toastMessage('Please select period');
      return;
    }
    if ((formData.period == 'month' || formData.period == 'day') && !formData.year  && this.page == 'summary') {
      this.msg.toastMessage('Please select year');
      return;
    }
  
    if (formData.period == 'day' && !formData.month  && this.page == 'summary') {
      this.msg.toastMessage('Please select month');
      return;
    }
    const data = {
      branch_id: this.branch ? this.branch : this.branchId,
      department_id: this.department ? this.department : this.departmentId,
      employee_id: this.employee ? this.employee : this.employeeId,
      leave_type: formData.leave_type,
      status: formData.status,
      due_days: formData.due_days,
      date: formData.date ? this.datePipe.transform(formData.date, 'y-MM-d') : '',
      time: formData.time ? this.datePipe.transform(formData.time, 'hh:mm:ss') : '',
      from_date: formData.from_date ? this.datePipe.transform(formData.from_date, 'y-MM-d') : '',
      to_date: formData.to_date ? this.datePipe.transform(formData.to_date, 'y-MM-d') : '',
      period: formData.period,
      year: moment(formData.year).format('YYYY'),
      month:  moment(formData.month).format('MM'),
      employeeName:  this.employeeName
    };
    this.modalCtrl.dismiss(data, 'search');
  }
}
