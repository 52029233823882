import { Injectable } from '@angular/core';
import { LocalNotifications } from '@ionic-native/local-notifications/ngx';

@Injectable({
  providedIn: 'root'
})
export class ShareDataService {
  private extra: any;
  constructor(
    private localNotifications: LocalNotifications
  ) {

  }
  public setExtras(data) {
      this.extra = data;
  }
  get getExtras() {
      return this.extra;
  }

  notification() {
    const year = new Date().getFullYear();
    const month = new Date().getMonth();
    const day = new Date().getDate();
    const time1 = new Date(year, month, day, 9, 15, 0, 0);
    const time2 = new Date(year, month, day, 5, 45, 0, 0);
    this.localNotifications.schedule([
      {
        id: 1,
        title: 'Attendance Mark',
        text: 'Please Mark Your Attendance',
        trigger: { at: new Date(time1) }
      },
      {
        id: 2,
        title: 'Attendance Mark',
        text: 'Please Mark Your Attendance',
        trigger: { at: new Date(time2) }
      }
    ]);
  }
 
}
