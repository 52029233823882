import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ApiService } from 'src/app/services/api.service';
import { AlertMessageService } from 'src/app/services/alert-message.service';

@Component({
  selector: 'app-search-employee',
  templateUrl: './search-employee.page.html',
  styleUrls: ['./search-employee.page.scss'],
})
export class SearchEmployeePage implements OnInit {
  text: any;
  Employee: any = [];
  Employee_search: any = [];
  info: any = [];
  info_data: any = [];
  user: any;
  employee_id: any;
  empty_employee: number;
  department_id: any;
  designation_id: any;
  type = false;
  itSelf = false
  constructor(
    private modal: ModalController,
    private request: ApiService,
    private message: AlertMessageService,
  ) {}
  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem('user'));
    this.employee_id = this.user.employee_id;
    this.getEmployee(this.department_id, this.designation_id);
  }
  async close() {
    await this.modal.dismiss({}, 'dismiss');
  }
  async selectField(data) {
    await this.modal.dismiss(data, 'search');
  }
  search() {
    this.Employee = [];
    if (!this.text) {
      this.Employee = this.Employee_search;
    } else {
      this.Employee_search.filter((item) => {
        if (item.employee_name && this.text) {
          if ( item.employee_name.toLowerCase().indexOf(this.text.toLowerCase()) > -1) {
            this.Employee.push(item);
          }
        }
      });
    }
  }
  getEmployee(depart_id, desig_id) {
    this.request.newPostRequest('getemployeesdropdown.php', {department_id: depart_id, designation: desig_id}).subscribe((res: any) => {
          // this.info_data = data;
          if (res.status === 'success') {
            if(this.itSelf){
              this.Employee = res.data;
            }else{
              for (let item of res.data) {
                if (item.emp_id !== this.employee_id) {
                  this.Employee.push(item);
                }
              }
            }
           
            if (this.type === true){
              this.Employee.unshift({emp_id: '0' , employee_name: 'All'});
            }
            this.Employee_search = this.Employee;
            this.empty_employee = (this.Employee.length === 0) ? 1 : this.Employee.length;
          } else {
            this.empty_employee = 1;
            if (this.info_data.data === 'Token Does Not Exist') {
              this.request.check_token();
             } else {
               this.message.toastMessage('No Record Found');
             }
          }
        },
        (err) => {
          this.empty_employee = 1;
          this.message.toastMessage(err.statusText);
        });
  }
}
