import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { AlertMessageService } from './alert-message.service';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  private $NotificationTask = new BehaviorSubject<Notify[]>([]);
  private $NotificationLeave = new BehaviorSubject<any>([]);
  public $user = new BehaviorSubject<any>([]);
  private $Status = new BehaviorSubject<any>([]);
  private $length = new BehaviorSubject<number>(0);
  user: any;
  groupId: any;
  employeeId: any;
  branchId: any;
  departmentId: any;
  constructor(
    private api: ApiService,
    private msg: AlertMessageService,
    private router: Router
  ) {}
  public get getNotificationTask(): Observable<Notify[]> {
    return this.$NotificationTask;
  }
  public get getNotificationLeave(): Observable<any> {
    return this.$NotificationLeave;
  }
  public get getStatus() {
    return this.$Status.value;
  }
  public get getLegth(): Observable<number> {
    return this.$length;
  }
  getNotificationTaskRequest(Id) {
    const data = { employee_id: Id };
    this.api.newPostRequest('shownotificationstask.php', data).subscribe(
      (res: any) => {
        if (res.status === 'success') {
          this.$NotificationTask.next(res.data);
          this.$length.next(res.data.length);
        } else {
        }
      },
      (err) => {
        this.msg.toastMessage(err.statusText);
      }
    );
  }
  getNotificationLeaveRequest(branch_id, department_id) {
    const data = { branch_id, department_id };
    console.log('data =>', data);
    this.api.newPostRequest('shownotificationsleave.php', data).subscribe(
      (res: any) => {
        console.log('notification =>', res);
        if (res.status === 'success') {
          this.$NotificationLeave.next(res.data);
          this.$length.next(res.data.leaves.length + res.data.halfleaves.length + res.data.loan.length);
        } else {
        }
      },
      (err) => {
        this.msg.toastMessage(err.statusText);
      }
    );
  }
  HideNotificationLeave(data) {
    this.msg.showLoader();
    this.api.newPostRequest('hidenotificationsleave.php', data).subscribe(
      (res: any) => {
        if (res.status === 'success') {
          this.$NotificationLeave.value.leaves.forEach((item, index) => {
            if (item.id === data.id) {
              this.$NotificationLeave.value.leaves.splice(index, 1);
              this.$length.next(this.$NotificationLeave.value.leaves.length +
                 this.$NotificationLeave.value.halfleaves.length + this.$NotificationLeave.value.loan.length);
            }
          });
          this.msg.hideLoader();
          this.router.navigate(['list-application']);
        } else {
          this.msg.hideLoader();
          if (res.data === 'Token Does Not Exist') {
            this.api.check_token();
           } else {
             this.msg.toastMessage('Something Went Wrong');
           }
        }
      },
      (err) => {
        this.msg.hideLoader();
        this.msg.toastMessage(err.statusText);
      }
    );
  }
  HideNotificationHalfLeave(data) {
    this.msg.showLoader();
    this.api.newPostRequest('hidenotificationshalfleave.php', data).subscribe(
      (res: any) => {
        if (res.status === 'success') {
          this.$NotificationLeave.value.halfleaves.forEach((item, index) => {
            if (item.id === data.id) {
              this.$NotificationLeave.value.halfleaves.splice(index, 1);
              this.$length.next(this.$NotificationLeave.value.leaves.length +
                this.$NotificationLeave.value.halfleaves.length + this.$NotificationLeave.value.loan.length);
            }
          });
          this.msg.hideLoader();
          this.router.navigate(['list-halfleave']);
        } else {
          this.msg.hideLoader();
          if (res.data === 'Token Does Not Exist') {
            this.api.check_token();
           } else {
             this.msg.toastMessage('Something Went Wrong');
           }
        }
      },
      (err) => {
        this.msg.hideLoader();
        this.msg.toastMessage(err.statusText);
      }
    );
  }
  HideNotificationLoan(data) {
    this.msg.showLoader();
    this.api.newPostRequest('hidenotificationsloan.php', data).subscribe(
      (res: any) => {
        if (res.status === 'success') {
          this.$NotificationLeave.value.loan.forEach((item, index) => {
            if (item.id === data.id) {
              this.$NotificationLeave.value.loan.splice(index, 1);
              this.$length.next(this.$NotificationLeave.value.leaves.length +
                this.$NotificationLeave.value.halfleaves.length + this.$NotificationLeave.value.loan.length);
            }
          });
          this.msg.hideLoader();
          this.router.navigate(['list-loan']);
        } else {
          this.msg.hideLoader();
          if (res.data === 'Token Does Not Exist') {
            this.api.check_token();
           } else {
             this.msg.toastMessage('Something Went Wrong');
           }
        }
      },
      (err) => {
        this.msg.hideLoader();
        this.msg.toastMessage(err.statusText);
      }
    );
  }

  HideNotificationTask(data) {
    this.msg.showLoader();
    this.api.newPostRequest('hidenotificationstask.php', data).subscribe(
      (res: any) => {
        if (res.status === 'success') {
          this.$NotificationTask.value.forEach((item, index) => {
            if (item.id === data.task_id) {
              this.$NotificationTask.value.splice(index, 1);
              this.$length.next(this.$NotificationTask.value.length);
            }
          });
          this.msg.hideLoader();
          this.router.navigate(['complete-task']);
        } else {
          this.msg.hideLoader();
          if (res.data === 'Token Does Not Exist') {
            this.api.check_token();
           } else {
             this.msg.toastMessage('Something Went Wrong');
           }
        }
      },
      (err) => {
        this.msg.hideLoader();
        this.msg.toastMessage(err.statusText);
      }
    );
  }

  getStatusValue() {
    this.api.newPostRequest('getStatus.php', {}).subscribe(
      (res: any) => {
        if (res.status === 'success') {
          this.$Status.next(res.data);
        } else {
        }
      },
      (err) => {
        this.msg.toastMessage(err.statusText);
      }
    );
  }

  execute() {
    this.user = JSON.parse(localStorage.getItem('user'));
    this.groupId = this.user.group_id;
    this.branchId = this.groupId === '7' ? 0 : this.user.branch;
    this.departmentId =
      this.groupId === '7' || this.groupId === '1' ? 0 : this.user.department;
    this.employeeId =
      this.groupId === '7' || this.groupId === '1' || this.groupId === '2'
        ? 0
        : this.user.employee_id;
    // if (this.groupId === '1' || this.groupId === '2' || this.groupId === '7') {
    //   this.getNotificationLeaveRequest(this.branchId, this.departmentId);
    // } else {
    //   this.getNotificationTaskRequest(this.employeeId);
    // }
    this.getStatusValue();
    this.getProfile();
 }

 getProfile() {
   if(!localStorage.getItem('token')){
     return;
   }
   const data = {
     user_id: localStorage.getItem('user_id'),
     token: localStorage.getItem('token'),
   }
  this.api.newPostRequest('get_user_info.php', data).subscribe((res: any) => {
    // console.log('profile res =>', res);    
    if (res.status === 'success') {
      this.$user.next(res.data);
        } else {
          if (res.data === 'Token Does Not Exist') {
            this.api.check_token();
           } else {
           }
        }
      },
      (err) => {
        // this.message.toastMessage(err.statusText);
      }
    );
}
HideNotifications(data) {
  this.msg.showLoader();
  let val = {id: data.id};
  console.log('val =>', val);
  this.api.newPostRequest('hide_notifications.php', val).subscribe(
    (res: any) => {
      if (res.status === 'success') {
        this.msg.hideLoader();
        switch(data.type){
          case '2':
              this.router.navigate(['view-application', {id: data.trans_id}]);
              break;
            case '3':
              this.router.navigate(['view-halfleave', {id: data.trans_id}]);
              break;
            case '4':
              this.router.navigate(['view-over-time', {id: data.trans_id}]); 
              break;  
          default:
            break;
        }    
        
        
      } else {
        this.msg.hideLoader();
        if (res.data === 'Token Does Not Exist') {
          this.api.check_token();
         } else {
           this.msg.toastMessage('Something Went Wrong');
         }
      }
    },
    (err) => {
      this.msg.hideLoader();
      this.msg.toastMessage(err.statusText);
    }
  );
}
}
export class Notify {
  id: string;
  name: string;
  date: string;
  department_id: string;
  description: string;
  employee_id: string;
  employee_name: string;
  end: string;
  leave_id: string;
  leave_type: string;
  showleave: string;
  start: string;
  status: string;
  subject: string;
  submitto: string;
  task_id?: string;
}
