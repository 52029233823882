import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HTTP } from '@ionic-native/http/ngx';
import { AlertMessageService } from './alert-message.service';
import { NavController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  baseUrl: any;

  constructor(
    private httpClient: HttpClient,
    private http: HTTP,
    private message: AlertMessageService,
    private navCtrl: NavController
    ) {
    // this.baseUrl = 'https://hrportal.safashacloud.com/web_api';  // web api live
    // this.baseUrl = 'https://hrportal.safashacloud.com/dev_web_api';  // dev web api live
    // this.baseUrl = 'http://localhost/managehr';
    this.baseUrl = 'https://manage-hr.toronto-pr.com/api';
  }
  newPostRequest(link, data) {
    data.token = localStorage.getItem('token') ? localStorage.getItem('token') : '';
    const formData = new FormData();
    Object.keys(data).forEach(key => {
      formData.append(key, data[key]);
    });
    return  this.httpClient.post(`${this.baseUrl}/${link}`, formData, {}).pipe();
  }
  check_token() {
      this.message.message_Alert('Error', 'Session has been expired. Kindly login again').then(() => {
        this.message.clearMemory();
        this.navCtrl.navigateRoot('login');
      });

  }
  postRequest(getLink, data) {
    data.token = localStorage.getItem('token') ? localStorage.getItem('token') : '';
    return new Promise((resolve, reject) => {
      this.http.post(`${this.baseUrl}/${getLink}`, data, {}).then(res => {
        const responseData = JSON.parse(res.data);
        if (responseData.data === 'Token Does Not Exist') {
          this.message.message_Alert('Error', 'Session has been expired. Kindly login again').then(() => {
            this.navCtrl.navigateRoot('login');
          });
        }
        resolve(res.data);
      }, err => {
        if (err.data === 'Token Does Not Exist') {
          this.message.message_Alert('Error', 'Session has been expired. Kindly login again').then(() => {
            this.navCtrl.navigateRoot('login');
          });
        }
        reject(err);
      }).catch(err => {
        if (err.data === 'Token Does Not Exist') {
          this.message.message_Alert('Error', 'Session has been expired. Kindly login again').then(() => {
            this.navCtrl.navigateRoot('login');
          });
        }
        reject(err);
      });
    });
  }
  getRequest(getLink) {
    return new Promise((resolve, reject) => {
      const data = {token : ''};
      data.token = localStorage.getItem('token') ? localStorage.getItem('token') : '';
      this.http.post(`${this.baseUrl}/${getLink}`, data, {}).then(res => {
        const data = JSON.parse(res.data);
        if (data.data === 'Token Does Not Exist') {
          this.message.message_Alert('Error', 'Session has been expired. Kindly login again');
        }
        resolve(res.data);
      }, err => {
        if (err.data === 'Token Does Not Exist') {
          this.message.message_Alert('Error', 'Session has been expired. Kindly login again').then(() => {
            this.navCtrl.navigateRoot('login');
          });
        }
        reject(err);
      }).catch(err => {
        if (err.data === 'Token Does Not Exist') {
          this.message.message_Alert('Error', 'Session has been expired. Kindly login again').then(() => {
            this.navCtrl.navigateRoot('login');
          });
        }
        reject(err);
      });
    });
  }
}
