import { Component, QueryList, ViewChildren, } from '@angular/core';
import { Platform, IonRouterOutlet, ToastController, NavController, MenuController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { ShareDataService } from './services/share-data.service';
import { CommonService } from './services/common.service';
import { Router } from '@angular/router';
import { AuthService } from './services/auth.service';



@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  appPages = [
    {
      title: 'Dashboard',
      url: '/dashboard',
      icon: '/assets/images/icons/icon_stats.svg',
    },
    {
    title: 'Profile',
    url: '/profile',
    icon: '/assets/images/icons/ic_profile.svg',
    
    },
    {
      title: 'Attendance',
      url: '/add-attendance',
      icon: '/assets/images/icons/ic_attendance.svg',
      
    },
    {
      title: 'Manual Attendance',
      url: '/manual-attendance',
      icon: '/assets/images/icons/ic_attendance.svg',
      
    },
    {
      title: 'Leaves',
      url: '/list-application',
      icon: '/assets/images/icons/ic_leaves.svg',
      
    },
    {
      title: 'Half Leaves',
      url: '/list-halfleave',
      icon: '/assets/images/icons/ic_half_leave.svg',
      
    },
    {
      title: 'Over Time',
      url: '/list-over-time',
      icon: '/assets/images/icons/ic_half_leave.svg',
      
    },
    {
      title: 'Loan',
      url: '/list-loan',
      icon: '/assets/images/icons/ic_loan.svg',
      
    },
    {
      title: 'Meal Expense Claim',
      url: '/list-expense',
      icon: '/assets/images/icons/ic_task.svg',
      
    },
    {
      title: 'Travel Form',
      url: '/complete-task',
      icon: '/assets/images/icons/ic_task.svg',
      
    },
    {
      title: 'Assign Task',
      url: '/assign-task',
      icon: '/assets/images/icons/ic_assign_task.svg',
      
    },
    // {
    //   title: 'Clients',
    //   url: '/list-client',
    //   icon: '/assets/images/icons/ic_clients.svg',
      
    // },
    {
      title: 'Reports',
      url: '/report',
      icon: '/assets/images/icons/ic_report.svg',
      
    },
    {
      title: 'Setup',
      url: '/setting',
      icon: '/assets/images/icons/ic_settings.svg',
      
    },
    {
      title: 'Logout',
      url: '',
      icon: '/assets/images/icons/ic_logout_white.svg'
    }
  ];
  userPages = [
    {
      title: 'Dashboard',
      url: '/dashboard',
      icon: '/assets/images/icons/icon_stats.svg',
    },
    {
      title: 'Profile',
      url: '/profile',
      icon: '/assets/images/icons/ic_profile.svg',
      
      },
      {
        title: 'Attendance',
        url: '/add-attendance',
        icon: '/assets/images/icons/ic_attendance.svg',
        
      },
      {
        title: 'Leaves',
        url: '/list-application',
        icon: '/assets/images/icons/ic_leaves.svg',
        
      },
      {
        title: 'Half Leaves',
        url: '/list-halfleave',
        icon: '/assets/images/icons/ic_half_leave.svg',
        
      },
      {
        title: 'Over Time',
        url: '/list-over-time',
        icon: '/assets/images/icons/ic_half_leave.svg',
        
      },
      {
        title: 'Loan',
        url: '/list-loan',
        icon: '/assets/images/icons/ic_loan.svg',
      },
      {
        title: 'Meal Expense Claim',
        url: '/list-expense',
        icon: '/assets/images/icons/ic_task.svg',
        
      },
      {
        title: 'Travel Form',
        url: '/complete-task',
        icon: '/assets/images/icons/ic_task.svg',
        
      },
      {
        title: 'Clients',
        url: '/list-client',
        icon: '/assets/images/icons/ic_clients.svg',
        
      },
      {
        title: 'Reports',
        url: '/report',
        icon: '/assets/images/icons/ic_report.svg',
        
      },
      {
        title: 'Logout',
        url: '',
        icon: '/assets/images/icons/ic_logout_white.svg'
      }
  ];

  user: any;
  groupId = '';
  menuOpen = false;
  @ViewChildren(IonRouterOutlet) routerOutlets: QueryList<IonRouterOutlet>;
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private shareData: ShareDataService,
    private common: CommonService,
    private router: Router,
    private authService: AuthService,
    private menu: MenuController
  ) {
    this.common.$user.subscribe((res: any) => {
      console.log('user res =>', res);

      this.groupId = res ? res.group_id : '';
    });
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
      if (localStorage.getItem('token')) {
        this.common.execute();
      }
      this.menuOpen = true;
      
      // this.shareData.notification();
    });
  }
  ForwardPage(page){
    try {
      if(page.title === 'Logout'){
        console.log('logout');
        this.authService.logout();
        return;
      }
        this.router.navigate([`${page.url}`]);
    
    } catch (error) {
      
  }
}
}
