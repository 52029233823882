import { Injectable } from '@angular/core';
import {
  AlertController,
  ToastController,
  LoadingController,
  NavController,
} from '@ionic/angular';
import { Router } from '@angular/router';
import { Storage } from '@ionic/storage';

@Injectable({
  providedIn: 'root',
})
export class AlertMessageService {
  private isLoading = false;
  constructor(
    private alertController: AlertController,
    private toast: ToastController,
    private loading: LoadingController,
    private navCtrl: NavController
  ) {}

  async logout() {
    const alert = await this.alertController.create({
      // tslint:disable-next-line: quotemark
      header: 'Manager HR',
      message: 'Are you sure you want to exit?',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'alertbtn',
          handler: (blah) => {},
        },
        {
          text: 'Exit',
          cssClass: 'alertbtn',
          handler: () => {
            this.clearMemory();
            this.navCtrl.navigateRoot('login');
            // this.storage.remove("user").then(() => {
            //   navigator["app"].exitApp();
            // });
            // this.router.navigate(['login'])
          },
        },
      ],
    });

    await alert.present();
  }
  async showLoader() {
    const checkLoader = await this.loading.getTop();
    if (checkLoader) {
      this.loading.dismiss();
    }
    const loading = await this.loading.create({
      message: 'Please Wait',
      spinner: 'bubbles',
    });
    loading.present();
  }
  async hideLoader() {
    await this.loading.dismiss();
  }
  async ifExistsLoader() {
    const checkLoader = await this.loading.getTop();
    if (checkLoader){
      this.loading.dismiss();
    }
  }
  async showMessage(header,msg) {
    const alert = await this.alertController.create({
      header: header,
      message: msg,
      buttons: ['Ok']
    });
    return await alert.present();
  }
  async toastMessage(msg) {
    const toast = await this.toast.create({
      message: msg,
      duration: 2000,
      position: 'bottom',
    });
    toast.present();
  }
 

  async presentLoading() {
    this.isLoading = true;
    const loading = await this.loading
      .create({
        message: 'Please Wait',
        spinner: 'bubbles',
      })
      .then((res) => {
        res.present();
        if (!this.isLoading) {
          this.loading.dismiss();
        }
      });
    return loading;
  }
  async hideLoading() {
    this.isLoading = false;
    return await this.loading.dismiss({});
  }

  async message_Alert(header, msg) {
    const alert = await this.alertController.create({
      header: header,
      message: msg,
      buttons: ['Ok'],
    });
    return await alert.present();
  }

  clearMemory() {
    localStorage.clear();
  }
}
